import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import * as R from "ramda"
import * as moment from 'moment'

import SearchContext from "../contexts/SearchContext"

import urlParse from "../utils/urlParse"
import toTitleCase from "../utils/toTitleCase"
import getLatLng from "../api/getLatLng"

import { Box, Container, Grid, Typography } from "@material-ui/core"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Section from "../components/page/Section"
import ImageCarousel from "../components/page/premises/ImageCarousel"

import Breadcrumb from "../components/navigation/Breadcrumb"
import PremisesLocationMap from "../components/page/premises/PremisesLocationMap"
import PremisesDescription from "../components/page/premises/PremisesDescription"
import KeyFigures from "../components/page/premises/KeyFigures"
import Table from "../components/page/premises/Table"
import Broker from "../components/page/premises/Broker"
import PremisesCard from "../components/page/search/PremisesCard"
import LeadForm from "../components/forms/LeadForm"

import PageActions from "../components/page/PageActions"

//import PremisesLift from "../components/page/PremisesLift"

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

import { makeStyles } from "@material-ui/core/styles"
import constants from "../components/theme/constants"
import colors from "../components/theme/colors"
import typography from "../components/theme/typography"

const isClient = typeof window !== "undefined"

const useStyles = makeStyles(theme => ({
  backButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    margin: "2em 0",
    color: colors.grey[2],

    "& span": {
      textTransform: "uppercase",
      fontSize: "0.9em",
    },
    "& svg": {
      width: "2rem",
      height: "2rem",
    },
  },
  sectionHeader: {
    marginBottom: "1.5rem",
  },
  postContent: {
    backgroundColor: colors.grey[0],
    //paddingTop: "2rem",
  },
  postContentHeader: {
    textTransform: "uppercase",
    marginBottom: "1.5rem",
  },
}))

const PremisesPage = ({ data, pageContext, location }) => {
  // To avoid a generic 404 error for space pages that no longer exist,
  // if the page is dynamically generated for the spaces the generate a
  // customized "missing" page.
  const { dynamic } = pageContext

  const seperator = " · "

  const [coords, setCoords] = useState({})

  const classes = useStyles()

  useEffect(() => {
  }, [classes])

  useEffect(() => {
    const fetchCoords = async () => {
      const { lat, lng } = await getLatLng({
        streetAddress: R.path(["premises", "streetAddress"], data),
        postcode: R.path(["premises", "postcode"], data),
        city: R.path(["premises", "city"], data),
      })
      setCoords({ lat, lng })
    }
    fetchCoords()
  }, [])

  const slides = R.pathOr([], ["premises", "media", "images"], data).map(
    image => {
      // check if image is a layout image
      const keepAspectRatio =
        R.path(["picType"], image) === 4 || R.path(["picClass"], image) === 12
          ? true
          : false
      return {
        alt: R.pathOr(
          `${R.pathOr("Kuva", ["premises", "streetAddress"], data)} kuva`,
          ["alt"],
          image
        ),
        priority: R.pathOr(Infinity, ["prio"], image),
        type: "image",
        imgId: R.path(["id"], image),
        keepAspectRatio,
      }
    }
  )

  if (R.path(["premises", "media", "main"], data)) {
    slides.unshift({
      alt: R.pathOr(
        `${R.pathOr("Kuva", ["premises", "streetAddress"], data)} kuva`,
        ["premises", "media", "main", "alt"],
        data
      ),
      priority: R.pathOr(0, ["premises", "media", "main", "prio"], data),
      type: "image",
      imgId: R.path(["premises", "media", "main", "id"], data),
    })
  }

  if (R.path(["premises", "media", "videos"], data)) {
    /* It seems that the content embedded can be quite random, so only use YouTube video Urls.
    Also, the YouTube URLs have been specified in 2 different formats, so convert to the main long format
    
    Formats:
    "https://www.youtube.com/watch?v=Sgy83eUAqwI"
    "https://youtu.be/kWlIMnGBD34"

    Only use:
    "https://www.youtube.com/watch?v=Sgy83eUAqwI"
    */

    const url = R.pathOr("", ["premises", "media", "videos"], data).match(
      /youtube\.com\/watch/
    )
      ? R.path(["premises", "media", "videos"], data)
      : R.pathOr("", ["premises", "media", "videos"], data).match(
          /youtu\.be\/(\w+)/
        )
      ? `https://www.youtube.com/watch?v=${
          R.pathOr("", ["premises", "media", "videos"], data).match(
            /youtu\.be\/(\w+)/
          )[1]
        }`
      : undefined

    if (url) {
      
      slides.push({
        alt: `YouTube ${R.pathOr("", ["premises", "streetAddress"], data)}`,
        priority: Infinity,
        type: "youtube",
        url: url,
      })
    }
  }

  if (R.path(["premises", "media", "tours"], data)) {
    slides.push({
      alt: "Tour",
      priority: Infinity,
      type: "tour",
      url: R.path(["premises", "media", "tours"], data),
    })
  }

  //R.sort((a,b)=>a.priority-b.priority,slides)

  // Improve the sorting to correctly do its stuff.

  /*
    As the data is not that easy to check in JSX, prepare the section data here for easier conditonal rendering.
    The data is static for the page, so it's fine to do it one once when rendering the page.

  */

  const keyFigures = [
    ...(R.path(["premises", "size", "advertiseSqMeters"], data)
      ? [
          {
            class: "area",
            title: "Pinta-ala",
            text: data.premises.size.advertiseSqMeters,
          },
        ]
      : []),
    ...(R.pathOr([], ["premises", "types"], data).length > 0
      ? [
          {
            class: "type",
            title: data.premises.types.length > 1 ? "Tyypit" : "Tyyppi",
            text: data.premises.types.join(", "),
          },
        ]
      : []),
    ...(R.path(["premises", "floor"], data)
      ? [
          {
            class: "floor",
            title: "Kerros",
            text: data.premises.floor,
          },
        ]
      : []),
    ...(R.pathOr(["premises", "city"], data)
      ? [
          {
            class: "city",
            title: "Kaupunki",
            text: data.premises.city,
          },
        ]
      : []),
  ]

  const showWorkspaces =
    R.pathOr([], ["premises", "types"], data).length === 0 ||
    R.pathOr([], ["premises", "types"], data).indexOf("Toimistotila") > -1
      ? true
      : false

  const basicInfo = {
    Ilmoitustyyppi:
      R.path(["premises", "onSale"], data) === "true"
        ? "Myynnissä"
        : "Vuokrataan",
    Sijainti: `${R.pathOr("", ["premises", "streetAddress"], data)}, ${R.pathOr("", ["premises", "streetAddress"], data).includes("Lapinlahdenkatu 3") && R.pathOr(
      "",
      ["premises", "postcodeArea"],
      data
    ).includes("RUOHOLAHTI") ? 'Kamppi, Helsinki' : R.pathOr(
      "", ["premises", "streetAddress"], data).includes("Lapinlahdenkatu 3") && R.pathOr(
      "",
      ["premises", "postcodeArea"],
      data
    ).toUpperCase().includes("RUOHOLAHTI") ? '00180<br>Kamppi, Helsinki' : R.pathOr(
      "",
      ["premises", "postcodeArea"],
      data
    )}`,
    Kaupunki: R.path(["premises", "city"], data),
    Tilatyyppi:
      R.pathOr([], ["premises", "types"], data).length > 0
        ? R.path(["premises", "types"], data).join(", ")
        : undefined,
    Kerros: R.path(["premises", "floor"], data),
    ...(R.path(["premises", "size", "advertiseSqMeters"], data) && {
      "Pinta-ala": `${R.path(
        ["premises", "size", "advertiseSqMeters"],
        data
      )} m<sup>2</sup>`,
    }),
    ...(R.path(["premises", "size", "minSqMetersProductionSpace"], data) &&
      R.path(["premises", "size", "maxSqMetersProductionSpace"], data) && {
        "Pinta-ala (Tuotantotila)": `${data.premises.size.minSqMetersProductionSpace} - ${data.premises.size.maxSqMetersProductionSpace} m<sup>2</sup>`,
      }),
    ...(R.path(["premises", "size", "minSqMetersLogistics"], data) &&
      R.path(["premises", "size", "maxSqMetersLogistics"], data) && {
        "Pinta-ala (Logistiikka/Varasto)": `${data.premises.size.minSqMetersLogistics} - ${data.premises.size.maxSqMetersLogistics} m<sup>2</sup>`,
      }),
    /*...(R.path(["premises", "size", "minSqMetersRetail"], data) &&
      R.path(["premises", "size", "maxSqMetersRetail"], data) && {
        "Pinta-ala (Liiketila)": `${data.premises.size.minSqMetersRetail} - ${data.premises.size.maxSqMetersRetail} m<sup>2</sup>`,
      }),*/
    ...(R.path(["premises", "size", "minSqMetersOffice"], data) &&
      R.path(["premises", "size", "maxSqMetersOffice"], data) && {
        "Pinta-ala (Toimistotila)": `${data.premises.size.minSqMetersOffice} - ${data.premises.size.maxSqMetersOffice} m<sup>2</sup>`,
      }),
    /* Only show these if the types of the premises is unkown or contains Toimitotila */
    ...(showWorkspaces &&
      R.path(["premises", "workstations", "activity"], data) && {
        Monitilatoimisto: `${Math.ceil(
          (data.premises.workstations.activity.min +
            data.premises.workstations.activity.max) /
            2
        )} hlö`,
      }),
    ...(showWorkspaces &&
      R.path(["premises", "workstations", "rooms"], data) && {
        Huonetoimisto: `${Math.ceil(
          (data.premises.workstations.rooms.min +
            data.premises.workstations.rooms.max) /
            2
        )} hlö`,
      }),
    ...(showWorkspaces &&
      (R.path(["premises", "workstations", "activity"], data) ||
        R.path(["premises", "workstations", "rooms"], data)) && {
        "": `Henkilömäärät ovat aina suuntaa antavia arvioita.`,
      }),
    /* need to find which ones these are in the data??
      Tilan jakaminen, Vapautuminen
    */
  }

  moment(R.path(["premises", "extraInfo", "vacancy"], data), 'YYYY-MM-DD').format('DD.MM.YYYY')

  const extraInfo = {
    ...(R.pathOr([], ["premises", "extraInfo", "certificates"], data).length >
      0 && {
      Ympäristösertifikaatti: R.path(
        ["premises", "extraInfo", "certificates"],
        data
      ).join(", "),
    }),
    // ...(R.pathOr(["premises", "extraInfo", "energyCertificate"], data) && {
    //   Energiatodistus: "Kyllä",
    // }),
    ...(R.path(["premises", "extraInfo", "energyCertificate"], data) ? {
      Energiatodistus: "Kyllä",
      } : {Energiatodistus:"Kohteella ei ole lain edellyttämää energiatodistusta"}) ,
    ...(R.path(["premises", "extraInfo", "energyClass"], data) && {
      Energialuokka: R.path(["premises", "extraInfo", "energyClass"], data),
    }),
    ...(R.pathOr([], ["premises", "extraInfo", "elevators"], data).length >
      0 && {
      Hissit: R.path(["premises", "extraInfo", "elevators"], data).join(", "),
    }),
    ...(R.path(["premises", "extraInfo", "coolingSystem"], data) && {
      Jäähdytysjärjestelmä: R.path(
        ["premises", "extraInfo", "coolingSystem"],
        data
      ),
    }),
    ...(R.path(["premises", "extraInfo", "internetClass"], data) && {
      Tietoliikenneyhteydet: R.path(
        ["premises", "extraInfo", "internetClass"],
        data
      ),
    }),
    ...(R.path(["premises", "extraInfo", "links"], data) && {
      Lisätietoa: `<a href="${R.path(
        ["premises", "extraInfo", "links"],
        data
      ).includes('https://') ? R.path(["premises", "extraInfo", "links"], data) : 'https://'.concat(R.path(["premises", "extraInfo", "links"], data))}" title="Lisätietoa" target="_blank" rel="noopener noreferrer">${
        urlParse(R.path(["premises", "extraInfo", "links"], data)).href
      }</a>`,
    }),
    ...(R.path(["premises", "extraInfo", "vacancy"], data) && {
      Vapautuu: moment(R.path(["premises", "extraInfo", "vacancy"], data), 'YYYY-MM-DD').format('DD.MM.YYYY'),
    }),
  }

  const buildingServices = {
    Aulapalvelut:
      R.pathOr([], ["premises", "building", "services"], data).indexOf(
        "Aulapalvelu"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Vuokrattavat neuvottelutilat":
      R.pathOr([], ["premises", "building", "services"], data).indexOf(
        "Vuokrattavat neuvottelutilat"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Latauspistokkeelliset autopaikat":
      R.pathOr([], ["premises", "building", "services"], data).indexOf(
        "Latauspistokkeelliset autopaikat"
      ) > -1
        ? "Kyllä"
        : undefined,
    Lounasravintola:
      R.pathOr([], ["premises", "building", "services"], data).indexOf(
        "Lounasravintola"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Vuokrattavat pysäköintipaikat":
      R.pathOr([], ["premises", "building", "services"], data).indexOf(
        "Vuokrattavat pysäköintipaikat"
      ) > -1
        ? "Kyllä"
        : undefined,
      "Sähköauton latauspaikka":
      R.pathOr([], ["premises", "building", "services"], data).indexOf(
        "Sähköauton latauspaikka"
      ) > -1
        ? "Kyllä"
        : undefined,
      "Vuokrattavia neuvottelutiloja":
      R.pathOr([], ["premises", "building", "services"], data).indexOf(
        "Vuokrattavia neuvottelutiloja"
      ) > -1
        ? "Kyllä"
        : undefined,
    Lisäpalvelut: R.path(["premises", "building", "extraInfoOffice"], data),
  }

  const buildingRetail = {
    "Kahvila- ja ravintolatilat":
      R.pathOr([], ["premises", "building", "retail"], data).indexOf(
        "Ravintolatilat"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Terveys- ja hyvinvointitilat":
      R.pathOr([], ["premises", "building", "retail"], data).indexOf(
        "Terveys- ja hyvinvointitilat"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Katutason liiketila":
      R.pathOr([], ["premises", "building", "retail"], data).indexOf(
        "Katutason liiketila"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Tila kauppakeskuksessa":
      R.pathOr([], ["premises", "building", "retail"], data).indexOf(
        "Kauppakeskuksessa oleva tila"
      ) > -1
        ? "Kyllä"
        : undefined,
    Lisäpalvelut: R.path(["premises", "building", "extraInfoRetail"], data),
  }

  const buildingLogistics = {
    Lastauslaituri:
      R.pathOr([], ["premises", "building", "logistics"], data).indexOf(
        "Lastauslaituri"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Nosto-ovet":
      R.pathOr([], ["premises", "building", "logistics"], data).indexOf(
        "Nosto-ovet"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Lattian kantavuus":
      R.pathOr([], ["premises", "building", "logistics"], data).indexOf(
        "Lattian kantavuus"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Tilan korkeus yli 4 m":
      R.pathOr([], ["premises", "building", "logistics"], data).indexOf(
        "Tilan korkeus yli 4 m"
      ) > -1
        ? "Kyllä"
        : undefined,
    "Pienvarastot (alle 100 m2)":
      R.pathOr([], ["premises", "building", "logistics"], data).indexOf(
        "Pienvarastot (alle 100 m2)"
      ) > -1
        ? "Kyllä"
        : undefined,
  }

  const buildingProduction = {
    Lastauslaituri:
      R.pathOr(
        [],
        ["premises", "building", "productionLogistics"],
        data
      ).indexOf("Lastauslaituri") > -1
        ? "Kyllä"
        : undefined,
    "Nosto-ovet":
      R.pathOr(
        [],
        ["premises", "building", "productionLogistics"],
        data
      ).indexOf("Nosto-ovet") > -1
        ? "Kyllä"
        : undefined,
    "Lattian kantavuus":
      R.pathOr(
        [],
        ["premises", "building", "productionLogistics"],
        data
      ).indexOf("Lattian kantavuus") > -1
        ? "Kyllä"
        : undefined,
    "Tilan korkeus yli 4 m":
      R.pathOr(
        [],
        ["premises", "building", "productionLogistics"],
        data
      ).indexOf("Tilan korkeus yli 4 m") > -1
        ? "Kyllä"
        : undefined,
  }

  const localServices = [
    ...(R.path(["premises", "distance", "tram"], data)
      ? [
          {
            class: "tram",
            title: "Raitiovaunu",
            text: data.premises.distance.tram,
          },
        ]
      : []),
    ...(R.path(["premises", "distance", "bus"], data)
      ? [
          {
            class: "bus",
            title: "Bussipysäkki",
            text: data.premises.distance.bus,
          },
        ]
      : []),
    ...(R.path(["premises", "distance", "train"], data)
      ? [
          {
            class: "train",
            title: "Juna",
            text: data.premises.distance.train,
          },
        ]
      : []),
    ...(R.path(["premises", "distance", "subway"], data)
      ? [
          {
            class: "subway",
            title: "Metro",
            text: data.premises.distance.subway,
          },
        ]
      : []),
    ...(R.path(["premises", "distance", "airport"], data)
      ? [
          {
            class: "airport",
            title: "Lentokenttä",
            text: data.premises.distance.airport,
          },
        ]
      : []),
  ]

  // Datalayer push on pageview
  const dataLayerObject = {
    'event':'kohteenKatselut',
    'ilmoitustyyppi': R.path(['premises', 'onSale'], data) === 'true' ? 'Myydään' : 'Vuokrataan',
    sijainti : `${R.path(['premises', 'streetAddress'], data)} ${R.path(['premises', 'postcodeArea'], data)}`,
    tilatyyppi : R.path(['premises', 'types'], data),
    'kerros': R.path(['premises', 'floor'], data),
    'pinta-ala': `${R.path(['premises', 'size', 'advertiseSqMeters'], data)} m2`,
    'external_id': R.path(['premises', 'id'], data),
    'monitilatoimisto': (showWorkspaces &&
    R.path(["premises", "workstations", "activity"], data)) ? `${Math.ceil(
      (R.path(['premises', 'workstations' ,'activity', 'min'], data) +
      R.path(['premises', 'workstations' ,'activity', 'max'], data)) /
        2
    )} hlö` : '',
    'huonetoimisto': (showWorkspaces &&
      R.path(["premises", "workstations", "rooms"], data)) ? `${Math.ceil(
        (R.path(['premises', 'workstations' ,'rooms', 'min'], data) +
        R.path(['premises', 'workstations' ,'rooms', 'max'], data)) /
          2
      )} hlö` : '',
  };

  // Push datalayer once
  useEffect(() => {
    // Push datalayer object
    if (typeof window !== 'undefined') {
      if(window.dataLayer){
        if(window.dataLayer.length > 0 && !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject)){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }else if(window.dataLayer.length === 0){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }
      }else{
        window.dataLayer = [];
        // eslint-disable-next-line no-undef
        window.dataLayer.push(dataLayerObject);
      }
    }
  }, [])

  const searchContext = useContext(SearchContext)
  searchContext.setHeroLoaded(true);

  let ogDescription = "";
  if (R.path(["premises", "pitch"], data) &&
    R.pathOr([],["premises", "building", "description"],data) &&
    R.pathOr([],["premises", "building", "description"],data).length > 0) {
      ogDescription = R.path(["premises", "pitch"], data) + " " + R.pathOr([],["premises", "building", "description"],data)[0];
  } else if (!R.path(["premises", "pitch"], data) &&
    R.pathOr([],["premises", "building", "description"],data) &&
    R.pathOr([],["premises", "building", "description"],data).length > 0) {
      ogDescription = R.pathOr([],["premises", "building", "description"],data)[0];
  }

  let premiseOnSale = R.path(["premises", "onSale"], data) === "true" ? "Myynnissä" : "Vuokrataan";
  let premiseType = R.path(['premises', 'types'], data) ? R.path(['premises', 'types'], data) : '';
  let premiseStreetAddress = R.pathOr("", ["premises", "streetAddress"], data) ? R.pathOr("", ["premises", "streetAddress"], data) : '';
  let premiseCity = R.path(["premises", "city"], data) ? R.path(["premises", "city"], data) : '';
  let premiseFloor = R.path(["premises", "floor"], data) ? R.path(["premises", "floor"], data) : '';
  let premiseSize = R.path(["premises", "size", "advertiseSqMeters"], data) ? R.path(["premises", "size", "advertiseSqMeters"], data) : '';
  let premisePostalCode = R.path(["premises", "postcode"], data) ? R.path(["premises", "postcode"], data) : '';
  let premiseTitle = `${premiseStreetAddress}, ${premiseCity} ${premisePostalCode} - ${premiseSize} m²`;
  let metaDesc = `${premiseOnSale} ${premiseType} ${premiseStreetAddress}, ${premiseCity}, Kerros ${premiseFloor}. Pinta-ala ${premiseSize} m²`;

  return (
    <Layout>
      <SEO
        title={premiseTitle}
        description={metaDesc}
        ogTitle={R.pathOr("", ["premises", "streetAddress"], data)}
        ogDescription={
          R.path(
            ["page", "openGraphDescription", "openGraphDescription"],
            data
          ) ||
          R.path(["page", "introDescription", "introDescription"], data) ||
          R.path(["page", "metaDescription", "metaDescription"], data) || 
          ogDescription
        }
        ogImg={
          R.path(["page", "openGraphImage", "file", "url"], data) ||
          R.path(["page", "headingImage", "file", "url"], data)
        }
      />
    
      <Container>
        <Link to="/toimitilat?f=true" style={{textDecoration: 'none'}}>
          <Box
            className={classes.backButton}
          >
            <NavigateBeforeIcon />
            <Typography variant="body1" component="span">
              Hakutuloksiin
            </Typography>
          </Box>
        </Link>

        <ImageCarousel slides={slides} />

        <Breadcrumb
          type={"Toimitilat"}
          city={R.path(["premises", "city"], data)}
          name={R.path(["premises", "streetAddress"], data)}
          slug={R.path(["premises", "slug"], data)}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Section>
           
              {R.path(["premises", "streetAddress"], data) && (
                <Typography variant="h2" component="h1">{`${
                  data.premises.streetAddress
                }${
                  R.path(["premises", "brandName"], data)
                    ? `${seperator}${data.premises.brandName}`
                    : ""
                }`}</Typography>
              )}
              {(R.path(["premises", "areas"], data) ||
                R.path(["premises", "postcode"], data) ||
                R.path(["premises", "city"], data)) && (
                <Typography variant="h5" component="h3">
                  {R.pathOr("", ["premises", "streetAddress"], data).includes("Lapinlahdenkatu 3") && R.pathOr(
                      "",
                      ["premises", "areas"],
                      data
                    ).join(", ").toUpperCase().includes("RUOHOLAHTI") ? "Kamppi, Helsinki" : R.pathOr([], ["premises", "areas"], data).join(", ")}
                  {/*R.pathOr([], ["premises", "areas"], data).join(", ")*/}
                  {R.path(["premises", "areas"], data) ? seperator : ""}
                  {R.pathOr("", ["premises", "postcode"], data)}{" "}
                  {R.pathOr("", ["premises", "city"], data).toUpperCase()}
                </Typography>
              )}
             
              <KeyFigures figures={keyFigures} type={"Toimitilat"} city={R.path(["premises", "city"], data)}/>
            </Section>

            {(R.pathOr([], ["premises", "premisesDescription"], data).length >
              0 ||
              R.pathOr([], ["premises", "building", "description"], data)
                .length > 0) && (
              <Section>    
                <PremisesDescription
                  headline={R.path(["premises", "pitch"], data)}
                  paragraphs={[
                    ...R.pathOr([], ["premises", "premisesDescription"], data),
                    ...R.pathOr(
                      [],
                      ["premises", "building", "description"],
                      data
                    ),
                  ]}
                />
              </Section>
            )}

            <Section>
              <Typography
                className={classes.sectionHeader}
                variant="h6"
                component="h3"
              >
                Perustiedot
              </Typography>
              <Table data={basicInfo} />
            </Section>

            {Object.keys(extraInfo).length > 0 ? (
              <Section>
                <Typography
                  className={classes.sectionHeader}
                  variant="h6"
                  component="h3"
                >
                  Lisätiedot
                </Typography>
                <Table data={extraInfo} />
              </Section>
            ) : null}

            {(R.pathOr([], ["premises", "building", "services"], data).length >
              0 ||
              R.path(["premises", "building", "extraInfoOffice"], data)) && (
              <Section>
                <Typography
                  className={classes.sectionHeader}
                  variant="h6"
                  component="h3"
                >
                  Kiinteistön palvelut
                </Typography>
                <Table data={buildingServices} />
              </Section>
            )}

            {(R.pathOr([], ["premises", "building", "retail"], data).length >
              0 ||
              R.path(["premises", "building", "extraInfoRetail"], data)) && (
              <Section>
                <Typography
                  className={classes.sectionHeader}
                  variant="h6"
                  component="h3"
                >
                  Liiketilan ominaisuudet
                </Typography>
                <Table data={buildingRetail} />
              </Section>
            )}

            {R.pathOr([], ["premises", "building", "logistics"], data).length >
              0 && (
              <Section>
                <Typography
                  className={classes.sectionHeader}
                  variant="h6"
                  component="h3"
                >
                  Varaston ominaisuudet
                </Typography>
                <Table data={buildingLogistics} />
              </Section>
            )}

            {R.pathOr([], ["premises", "building", "productionLogistics"], data)
              .length > 0 && (
              <Section>
                <Typography
                  className={classes.sectionHeader}
                  variant="h6"
                  component="h3"
                >
                  Tuotantotilan ominaisuudet
                </Typography>
                <Table data={buildingProduction} />
              </Section>
            )}
            {localServices.length > 0 && (
              <Section>
                <Typography
                  className={classes.sectionHeader}
                  variant="h6"
                  component="h3"
                >
                  Liikenneyhteydet
                </Typography>

                <KeyFigures figures={localServices} />
              </Section>
            )}
            <Section dividerBottom={false}>
              <PageActions
                href={isClient ? window.location.href : "#"}
                address={R.pathOr("", ["premises", "streetAddress"], data)}
              />
            </Section>
          </Grid>
          <Grid item xs={12} md={5}>
            {coords.lat && coords.lng && (
              <Section noDivider>
                <PremisesLocationMap
                  lat={coords.lat}
                  lng={coords.lng}
                  text={`${R.pathOr(
                    "",
                    ["premises", "streetAddress"],
                    data
                  )}, ${R.pathOr(
                    "",
                    ["premises", "postcode"],
                    data
                  )} ${toTitleCase(R.pathOr("", ["premises", "city"], data))}`}
                />
              </Section>
            )}
            <Section>
              <LeadForm
                title={R.pathOr(
                  "Kiinnostuitko?",
                  ["site", "leadFormTitle"],
                  data
                )}
                text={R.pathOr(
                  "Olemme sinuun pian yhteydessä.",
                  ["site", "leadFormText", "leadFormText"],
                  data
                )}
                thanksTitle={R.pathOr(
                  "Kiitos yhteydenotostasi!",
                  ["site", "leadFormThankYouTitle"],
                  data
                )}
                thanksText={R.pathOr(
                  "Olemme sinuun yhteydessä. Sillä välin voit lukea toimitilan valintaan liittyviä vinkkejämme.",
                  ["site", "leadFormThankYouText", "leadFormThankYouText"],
                  data
                )}
                defaultMessage={`Olen kiinnostunut kohteestanne osoitteessa ${R.pathOr(
                  "",
                  ["premises", "streetAddress"],
                  data
                )}, ${R.pathOr(
                  "",
                  ["premises", "postcode"],
                  data
                )} ${toTitleCase(R.pathOr("", ["premises", "city"], data))}`}
                meta={{
                  premises: {
                    brokers: R.pathOr([], ["contacts", "nodes"], data).map(
                      b => {
                        return {
                          ...(b.name && { name: b.name }),
                          ...(b.email && { email: b.email }),
                        }
                      }
                    ),
                    pageUrl: location.href,
                    address: R.path(["premises", "streetAddress"], data),
                    propertyId: R.path(['premises', 'id'], data)
                  },
                }}
              />
            </Section>
            {R.pathOr([], ["contacts", "nodes"], data).length > 0 && (
              <Broker
                forSale={R.path(["premises", "onSale"], data)}
                contactPersons={R.pathOr([], ["contacts", "nodes"], data)}
              />
            )}
          </Grid>
        </Grid>
      </Container>

      <Box className={classes.postContent}>
        <SearchContext.Consumer>
          {context => {
            /* Get three random indexes from either current search results */
            //Math.floor(Math.random()*myArray.length);

            // Also bug here when landing directory to space page, filteredSpaces is empty for some reason */

            return (
              <Container>
                {context.filteredPremises.length >= 3 && (
                  <Box className={classes.recommendedSpaces}>
                    <Typography
                      className={classes.postContentHeader}
                      variant="h3"
                      component="h5"
                    >
                      Sinua voisi kiinnostaa
                    </Typography>
                    <Grid container spacing={5}>
                      {context.filteredPremises
                        .slice(0, 6)
                        .map((premises, idx) => {
                          return (
                            <Grid key={idx} item xs={12} md={4}>
                              <PremisesCard premises={premises} isLift={true}/>
                            </Grid>
                          )
                        })}
                    </Grid>
                  </Box>
                )}

                {/*context.autocompleteFilters.filter((location)=>R.path(["city"],location)===R.pathOr('missing',['premises','city'], data))}
                  {/*<Typography className={classes.postContentHeader} variant="h3" component="h5">
                    Muut kaupungin alueet
                  </Typography>*/}
              </Container>
            )
          }}
        </SearchContext.Consumer>
      </Box>
    </Layout>
  )
}

export default PremisesPage

export const pageQuery = graphql`
  query premisesPageQuery($slug: String, $contactIds: [String]) {
    premises: newsecPremises(slug: { eq: $slug }) {
      id
      slug
      name
      brandName
      streetAddress
      areas
      city
      postcode
      postcodeArea
      floor
      types
      layout
      extraInfo {
        elevators
        energyClass
        energyCertificate
        certificates
        coolingSystem
        internetClass
        links
        vacancy
      }
      distance {
        bus
        tram
        subway
        train
        airport
      }
      pitch
      premisesDescription
      size {
        advertiseSqMeters
        minSqMetersOffice
        maxSqMetersOffice
        minSqMetersLogistics
        maxSqMetersLogistics
        minSqMetersProductionSpace
        maxSqMetersProductionSpace
      }
      workstations {
        activity {
          min
          max
        }
        open {
          min
          max
        }
        rooms {
          min
          max
        }
      }
      building {
        newProduction
        description
        services
        logistics
        productionLogistics
        retail
        extraInfoOffice
        extraInfoRetail
      }
      promoted
      onSale
      media {
        main {
          alt
          ext
          id
          name
          picClass
          picType
          prio
        }
        images {
          alt
          ext
          id
          name
          picClass
          picType
          prio
        }
        tours
        videos
      }
      mainContactIds
    }
    contacts: allNewsecContactPerson(filter: { id: { in: $contactIds } }) {
      nodes {
        id
        name
        email
        phone
        photo {
          alt
          ext
          name
        }
      }
    }
    site: contentfulSiteGlobal(activeVersion: { eq: "Kyllä" }) {
      leadFormTitle
      leadFormText {
        leadFormText
      }
      leadFormThankYouText {
        leadFormThankYouText
      }
      leadFormThankYouTitle
    }
  }
`
